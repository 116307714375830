.recipe {
    overflow-y: scroll;
    position: relative;

    .heading-container {
        margin-bottom: 40px;

        .heading {
            margin-bottom: 0;
            @include down($mobile-xl) {
                font-size: 24px;
            }
            @include down($mobile) {
                font-size: 20px;
            }
            @include down($small-mobile) {
                font-size: 16px;
            }
            @include down($extra-small) {
                font-size: 14px;
            }
        }

        .custom-heading-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 8px;
        }

        .recipe-credit {
            font-weight: 400;
            font-size: 12px;
        }

        .list-img {
            height: 24px;
            cursor: pointer;
        }
    }

    .recipe-details {
        .recipe-image,
        .additional-recipe-image,
        .additional-recipe-video {
            width: 350px;
            margin-bottom: 24px;
            @include down($mobile) {
                width: 100%;
            }

            display: flex;
            justify-content: center;
            align-items: center;
            background: $primary-blue;
            color: $black;
            &.empty {
                aspect-ratio: 1 / 1;
            }
        }

        .prep-time {
            margin-bottom: 8px;
        }

        .cook-time {
            margin-bottom: 8px;
        }

        .wait-time {
            margin-bottom: 8px;
        }

        .inaccurate-badge {
            color: $white;
            font-size: 10px;
        }

        .yields-container {
            margin-bottom: 24px;
            display: flex;
            flex-direction: column;
            row-gap: 6px;

            .yields {
                display: flex;
                column-gap: 8px;

                .yield-text {
                    display: inline-block;
                    margin-top: 6px;
                }

                .yield-amount-container {
                    display: flex;
                    flex-direction: row;
                    column-gap: 8px;
                    align-items: center;

                    @include down($mobile) {
                        flex-direction: column;
                        row-gap: 8px;
                        align-items: flex-start;
                    }

                    .applied-yield-input-container {
                        display: flex;
                        column-gap: 5px;
                        align-items: center;

                        .applied-yield-input {
                            padding: 6px 8px;
                            width: 60px;
                        }
                    }

                    .dash {
                        @include down($mobile) {
                            display: none;
                        }
                    }

                    .quick-yield-adjustment-container {
                        display: flex;
                        column-gap: 8px;
                        align-items: center;
                        .quick-yield-adjustment {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: 4px 8px;
                            border: 1px solid $default;
                            border-radius: 4px;
                            cursor: pointer;
                            &.active {
                                background: $primary-blue;
                                color: $white;
                                border: 1px solid $primary-blue;
                            }
                        }
                    }
                }
            }
        }

        .recipe-ingredients-label-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            column-gap: 16px;
            row-gap: 8px;
            margin-bottom: 4px;

            .recipe-detail-label {
                margin-bottom: 0;
            }

            .grocery-list-button-container {
                display: flex;
                align-items: center;
                column-gap: 24px;

                span {
                    color: $primary-blue;
                }

                .select-all-button {
                    cursor: pointer;
                }

                .add-to-list-button {
                    color: $default;
                    &.active {
                        color: $primary-blue;
                        cursor: pointer;
                    }
                }
            }
        }

        .recipe-detail-label {
            margin-bottom: 4px;
            font-size: 20px;
        }

        .recipe-detail-list {
            margin-bottom: 40px;
            list-style: none;
            &.numbered {
                list-style-type: decimal;
                padding-left: 24px;
                li {
                    margin-bottom: 8px;
                }
            }
            li {
                margin-bottom: 4px;
                line-height: 1.35;
            }
        }

        .recipe-container,
        .separated-recipe-container {
            margin-bottom: 48px;
            .separated-recipe-detail-label {
                margin-bottom: 8px;
                text-decoration: underline;
                text-underline-offset: 4px;
            }
            .sectioned-recipe-container {
                margin-bottom: 24px;
            }
            .checkbox-ingredient-container {
                display: flex;
                align-items: flex-start;
                margin-bottom: 6px;
                .checkbox-ingredient {
                    margin-right: 6px;
                    min-height: 16px;
                    min-width: 16px;
                    transform: translateY(2px);
                }
                .ingredient-label-with-link {
                    color: $primary-blue;
                    text-underline-offset: 4px;
                    cursor: pointer;
                }
                .ingredient-label-with-link,
                .ingredient-label {
                    display: inline;
                    cursor: pointer;
                }
                .ingredient-additional-details {
                    color: $darkgrey;
                }
            }
            .separated-recipe-detail-list {
                margin-bottom: 24px;
                padding-left: 16px;
                &.numbered {
                    list-style-type: decimal;
                    padding-left: 24px;
                    margin-bottom: 0;
                    li {
                        margin-bottom: 8px;
                    }
                }
                li {
                    margin-bottom: 4px;
                }
            }
            &:last-of-type {
                .separated-recipe-detail-list {
                    margin-bottom: 40px;
                }
            }
        }

        .notes-label {
            margin-top: 32px;
        }

        .recipe-notes {
            padding-left: 16px;
            li {
                margin-bottom: 8px;
                line-height: 1.35;

                .note-image {
                    margin-top: 12px;
                    width: 350px;
                    margin-bottom: 24px;
                    @include down($mobile) {
                        width: 100%;
                    }
                }
            }
        }

        .figure-label-anchor {
            color: $primary-blue;
            display: inline-block;
            margin-left: 4px;
        }

        .figures-container {
            margin-top: 40px;
            .figure-container {
                display: flex;
                flex-direction: column;

                label {
                    margin-bottom: 6px;
                    color: $primary-blue;
                }
            }
        }

        .link-text {
            color: $primary-blue;
            text-decoration: underline;
            text-underline-offset: 4px;
        }

        .recipe-websites-list {
            display: flex;
            flex-direction: column;
            row-gap: 16px;
            margin-top: 8px;
            padding-left: 16px;

            .recipe-website-item {
                color: $primary-blue;

                a {
                    color: $primary-blue;
                    text-decoration: unset;
                    text-underline-offset: 4px;
                }
            }
        }
    }

    .loader-container {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 200px;
        .watermelon {
            transform: unset;
            position: relative;
            left: unset;
            top: unset;
        }
    }

    .add-to-grocery-list-modal {
        position: absolute;
        top: 50vh;
        left: -50%;
        z-index: 5;
        background: $dark-aqua;
        transform: translate(-50%, -50%);
        width: unquote("min(calc(100% - 16px), 600px)");
        padding: 24px 16px;
        &.opened {
            left: 50%;
            animation: slide-up 0.75s ease-in-out;
        }
        &.closed {
            left: -50%;
            animation: slide-down 0.75s ease-in-out;
        }

        @keyframes slide-up {
            0% {
                left: -50%;
            }
            100% {
                left: 50%;
            }
        }

        @keyframes slide-down {
            0% {
                left: 50%;
            }
            100% {
                left: -50%;
                display: none;
            }
        }

        .modal-content {
            display: flex;
            flex-direction: column;
            row-gap: 40px;
            justify-content: space-between;
            height: 100%;

            .modal-header {
                display: flex;
                justify-content: space-between;
                width: 100%;
                column-gap: 16px;
                h3 {
                    font-size: 16px;
                    font-weight: 400;
                }
                img {
                    width: 19px;
                    height: 19px;
                    cursor: pointer;
                    padding: 1.5px;
                }
            }

            .top-container {
                display: flex;
                flex-direction: column;
                row-gap: 16px;

                .modal-body {
                    display: flex;
                    flex-direction: column;
                    row-gap: 16px;

                    .edit-recipe-date-range-container {
                        display: flex;
                        column-gap: 12px;
                        position: relative;
                        & > div {
                            width: 50%;
                            position: unset;
                        }
                    }

                    .edit-recipe-date-input {
                        width: 100%;
                        padding: 8px 12px;
                        outline: none;
                        border: 1.5px solid $default;
                        background: $aqua;
                        color: $white;
                        font-size: 14px;
                        position: relative;
                        .edit-recipe-date-label-container {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                        }
                        .edit-recipe-date-label {
                            display: inline-block;
                            width: 100%;
                            cursor: pointer;
                            &.is-default {
                                color: $default;
                            }
                        }
                        .delete-date-btn {
                            height: 12px;
                            cursor: pointer;
                        }
                    }

                    .edit-recipe-meal-date-inputs-container {
                        display: flex;

                        .edit-recipe-meal-date-input-container {
                            position: relative;

                            .edit-recipe-date-label-container {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;

                                .delete-date-btn {
                                    height: 12px;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
            }

            .modal-footer {
                display: flex;
                justify-content: flex-end;
                column-gap: 16px;

                button {
                    padding: 6px 12px;
                    border-radius: 16px;
                    outline: none;
                    border: 1.5px solid transparent;
                    &.cancel-btn {
                        color: $white;
                        background: $black;
                        cursor: pointer;
                        &:hover {
                            background: $white;
                            color: $black;
                            border: 1.5px solid $default;
                        }
                    }

                    &.add-btn {
                        background: $primary-blue;
                        color: $white;
                        cursor: pointer;
                        &:hover {
                            background: $white;
                            color: $primary-blue;
                            border: 1.5px solid $primary-blue;
                        }
                    }
                }
            }
        }
    }
}
